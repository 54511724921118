
import { Options, Vue } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import ContentLogsViewNew from "./components/content-logs-view-new.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";
import moment from 'moment';

@Options<OtherLogsViewPage>({
  name: "content-logs-view-page",
  components: {
    Page,
    LogsViewFilter,
    ContentLogsViewNew,
  },
})
export default class OtherLogsViewPage extends Vue {
  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();

  onclickFilterChange() {
    (this.$refs.page as ContentLogsViewNew).onFilterChange();
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
}
