<template>
  <page name="Просмотр логов контента" :selectSource="false">
    <template v-slot:toolbar>
      <filter-component
        v-model:filter="filterModel"
        @filterData="onclickFilterChange"
        :selectInterval="false"
        :methods="false"
      ></filter-component>
    </template>
    <template v-slot="slotProps">
      <content-logs-view-new :filter="filterModel" ref="page"></content-logs-view-new>
      <bs-row v-if="!filterModel">
        <bs-col>
          <div class="set-filter">Нажмите кнопку показать!</div>
        </bs-col>
      </bs-row>
    </template>
  </page>
</template>

<script lang="ts">
import { Options, Vue } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import ContentLogsViewNew from "./components/content-logs-view-new.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";
import moment from 'moment';

@Options<OtherLogsViewPage>({
  name: "content-logs-view-page",
  components: {
    Page,
    LogsViewFilter,
    ContentLogsViewNew,
  },
})
export default class OtherLogsViewPage extends Vue {
  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();

  onclickFilterChange() {
    (this.$refs.page as ContentLogsViewNew).onFilterChange();
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
}
</script>

<style lang="less">
.other-logs-view-page {
  .min-width {
    min-width: 150px;
  }
}
</style>